@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "NewSpirit";
  src: local("NewSpirit"),
    url("./assets/font/NewSpirit.ttf") format("truetype");
  font-weight: normal;
}

@layer base {
  * {
    @apply font-sans text-white;
  }
  body {
    @apply bg-gray-primary;
  }
}

@layer components {
  .container {
    @apply w-[90%] mx-auto max-w-[1600px];
  }

  .text-gradient {
    @apply bg-text-gradient bg-clip-text text-transparent;
  }

  .black-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 101 !important;
    transition: all 0.3s ease;
    opacity: 0;
    pointer-events: none;
  }
  .black-screen.show {
    opacity: 1;
    pointer-events: visible;
  }
}

/* Hexagon Loading */

.hexagon {
  --bg: #e3e4e8;
  --fg: #17181c;
  --dur: 8s;
  color: var(--fg);
  display: grid;
  place-items: center;
  align-content: center;
  height: 100vh;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 1.5em;
  overflow: hidden;
  position: relative;
  width: 15em;
  height: 15em;
}

.hexagon__group,
.hexagon__sector,
.hexagon__sector:before,
.hexagon__sector:after {
  position: absolute;
}

.hexagon__group {
  width: 100%;
  height: 100%;
}

.hexagon__group:nth-child(2) .hexagon__sector,
.hexagon__group:nth-child(2) .hexagon__sector:before,
.hexagon__group:nth-child(2) .hexagon__sector:after {
  animation-delay: calc(var(--dur) * -1/6);
}

.hexagon__group:nth-child(3) .hexagon__sector,
.hexagon__group:nth-child(3) .hexagon__sector:before,
.hexagon__group:nth-child(3) .hexagon__sector:after {
  animation-delay: calc(var(--dur) * -2/6);
}

.hexagon__group:nth-child(4) .hexagon__sector,
.hexagon__group:nth-child(4) .hexagon__sector:before,
.hexagon__group:nth-child(4) .hexagon__sector:after {
  animation-delay: calc(var(--dur) * -3/6);
}

.hexagon__group:nth-child(5) .hexagon__sector,
.hexagon__group:nth-child(5) .hexagon__sector:before,
.hexagon__group:nth-child(5) .hexagon__sector:after {
  animation-delay: calc(var(--dur) * -4/6);
}

.hexagon__group:nth-child(6) .hexagon__sector,
.hexagon__group:nth-child(6) .hexagon__sector:before,
.hexagon__group:nth-child(6) .hexagon__sector:after {
  animation-delay: calc(var(--dur) * -5/6);
}

.hexagon__group:nth-child(odd) {
  transform: rotate(30deg);
}

.hexagon__sector,
.hexagon__sector:before,
.hexagon__sector:after {
  animation-duration: var(--dur);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  /*animation-play-state: paused;*/
  width: 0.2em;
  height: 0.2em;
}

.hexagon__sector {
  animation-name: moveOut1;
  top: calc(50% - 0.1em);
  left: calc(50% - 0.1em);
}

.hexagon__sector:nth-child(2) {
  animation-name: moveOut2;
}

.hexagon__sector:nth-child(3) {
  animation-name: moveOut3;
}

.hexagon__sector:nth-child(4) {
  animation-name: moveOut4;
}

.hexagon__sector:nth-child(5) {
  animation-name: moveOut5;
}

.hexagon__sector:nth-child(6) {
  animation-name: moveOut6;
}

.hexagon__sector:before,
.hexagon__sector:after {
  animation-name: ripple;
  background-color: white;
  border-radius: 0.1em;
  content: "";
  display: block;
  top: 0;
  left: 0;
  transform-origin: 0.1em 0.1em;
}

.hexagon__sector:before {
  transform: rotate(-30deg)
}

.hexagon__sector:after {
  transform: rotate(-150deg)
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: #17181c;
    --fg: #e3e4e8;
  }
}

/* Animations */
@keyframes moveOut1 {
  from {
    transform: translateY(0) scale(0);
  }

  3% {
    transform: translateY(0.2em) scale(1);
  }

  97% {
    transform: translateY(7.3em) scale(1);
  }

  to {
    transform: translateY(7.5em) scale(0);
  }
}

@keyframes moveOut2 {
  from {
    transform: rotate(60deg) translateY(0) scale(0);
  }

  3% {
    transform: rotate(60deg) translateY(0.2em) scale(1);
  }

  97% {
    transform: rotate(60deg) translateY(7.3em) scale(1);
  }

  to {
    transform: rotate(60deg) translateY(7.5em) scale(0);
  }
}

@keyframes moveOut3 {
  from {
    transform: rotate(120deg) translateY(0) scale(0);
  }

  3% {
    transform: rotate(120deg) translateY(0.2em) scale(1);
  }

  97% {
    transform: rotate(120deg) translateY(7.3em) scale(1);
  }

  to {
    transform: rotate(120deg) translateY(7.5em) scale(0);
  }
}

@keyframes moveOut4 {
  from {
    transform: rotate(180deg) translateY(0) scale(0);
  }

  3% {
    transform: rotate(180deg) translateY(0.2em) scale(1);
  }

  97% {
    transform: rotate(180deg) translateY(7.3em) scale(1);
  }

  to {
    transform: rotate(180deg) translateY(7.5em) scale(0);
  }
}

@keyframes moveOut5 {
  from {
    transform: rotate(240deg) translateY(0) scale(0);
  }

  3% {
    transform: rotate(240deg) translateY(0.2em) scale(1);
  }

  97% {
    transform: rotate(240deg) translateY(7.3em) scale(1);
  }

  to {
    transform: rotate(240deg) translateY(7.5em) scale(0);
  }
}

@keyframes moveOut6 {
  from {
    transform: rotate(300deg) translateY(0) scale(0);
  }

  3% {
    transform: rotate(300deg) translateY(0.2em) scale(1);
  }

  97% {
    transform: rotate(300deg) translateY(7.3em) scale(1);
  }

  to {
    transform: rotate(300deg) translateY(7.5em) scale(0);
  }
}

@keyframes ripple {

  from,
  to {
    width: 0.2em;
  }

  33% {
    width: 2.4em;
  }
}

/* Phone Input */
.PhoneInput .PhoneInputInput {
  outline: none;
  border: none;
  font-size: 18px;
  padding-bottom: 12px;
  padding-top: 12px;
}

.PhoneInput .PhoneInputInput:fo {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}